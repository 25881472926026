import { Navigate, Outlet } from "react-router";

const PublicRoutes = () => {
  const isallowded = localStorage.getItem("token");

  const role = localStorage.getItem("role");

  return !isallowded ? (
    <Outlet />
  ) : role === "admin" ? (
    <Navigate to="/companies" />
  ) : role === "user" ? (
    <Navigate to="/userprofile" />
  ) : role === "company" ? (
    <Navigate to="/addusers" />
  ) : (
    <Navigate to="/" />
  );
};

export default PublicRoutes;

