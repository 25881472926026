import { useNavigate } from "react-router";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useState } from "react";

import { read, utils, writeFile } from "xlsx";
// import "./styles.css";
import { Button, CircularProgress } from "@mui/material";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import Swal from "sweetalert2/dist/sweetalert2";

function UploadUsers() {
  const [name, setName] = useState([]);
  const navigate = useNavigate();
  const [upload, setUploads] = useState(false);
  const [uploaded, setUploaded] = useState(0);

  const handleImport = ($event) => {
    const { files } = $event.target;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          setUploads(true);

          const token = localStorage.getItem("token");

          const data = { rows, token };

          axios
            .post(`${process.env.REACT_APP_API_PATH}/saveuser`, data, {
              onDownloadProgress: (progressEvent) => {
                const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
                setUploaded(progress);
              },
            })
            .then((res) => {
              if (res.data.status == 200) {
                Swal.fire("Done!", " Upload successful", "success");
                navigate("/addusers");
              }
            })
            .catch((err) => console.log(err));

          setName(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleExport = () => {
    const headings = [["firstname", "lastname", "email", "contact", "city", "country", "role"]];
    const wb = utils.book_new();

    const ws = utils.json_to_sheet([]);

    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, name);
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "UserData Report.xlsx");
  };

  return (
    <DashboardLayout>
      <Sidenav color="Primary" brandName="Connectar" routes={routes} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={{ xs: -3, sm: -5, md: -16, lg: -16 }}
                py={3}
                px={2}
                variant="gradient"
                sx={{backgroundColor:"#1e90ff"}}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white" style={{ float: "left" }}>
                  To add user you have to download this template!
                </MDTypography>
              </MDBox>
              <MDBox mx={2} py={3} px={2}>
                <MDTypography variant="h5" color="black" style={{ float: "left" }}>
                  Choose file
                  <br />
                  <input
                    style={{ fontSize: "20px" }}
                    type="file"
                    name="file"
                    className="custom-file-input"
                    id="inputGroupFile"
                    required
                    onChange={handleImport}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <br />
                  <MDBox mx={2} py={3} px={2}>
                    {upload && uploaded != 100 ? (
                      <>
                        <CircularProgress />
                        <h6>Uploading...</h6>
                      </>
                    ) : (
                      ""
                    )}
                  </MDBox>
                </MDTypography>
                <Button
                  variant="contained"
                  mt={5}
                  style={{
                    background: " #0d6efd",
                    float: "right",
                    color: "white",
                    fontSize: "18px",
                  }}
                  onClick={handleExport}
                >
                  Template <DownloadOutlinedIcon />
                </Button>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default UploadUsers;
