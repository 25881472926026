import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editorStyles.css"; 
const WYSIWYGEditor = ({ value, onChange }) => {
  return (
    <div
     className="react-quill-container"
    >
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }],
            ["blockquote", "code-block"],
            [{ font: [] }],
          ],
        }}
       
      />{" "}
      
     
    </div>
  );
};

export default WYSIWYGEditor;
