import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { Card, Grid, Box, Avatar, Button, CircularProgress, Tooltip, IconButton } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreatePage from "./CreatePage";
import DataTable from "examples/Tables/DataTable";
import EditPage from "./EditPage";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
const stripHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
const PageList = () => {
  const apiBaseUrl = process.env.REACT_APP_API_PATH;
  const [pages, setPages] = useState([]);
  const [error, setError] = useState(null);
const [isLoading, setIsLoading] = useState(false);
const [copiedSlug, setCopiedSlug] = useState(null);
  const fetchPages = async () => {
    setIsLoading(true); 
    try {
      const response = await axios.get(`${apiBaseUrl}/pages/getPages`);
      setPages(response.data);
    } catch (err) {
      setError("Failed to fetch pages");
    }
    finally {
      setIsLoading(false); 
    }
  };
  useEffect(() => {
    fetchPages();
  }, [apiBaseUrl]);

  const deletePage = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Page record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${apiBaseUrl}/admin/pages/${id}`)
          .then((res) => {
            // toast.success("delete post successfully!");
            Swal.fire("Great!", "Page deleted successfully.", "success");

           setPages((prevPages) => prevPages.filter((page) => page._id !== id));
          })
          .catch((err) => {
            console.log(err);
            toast.error("An error occurred while deleting the record.");
          });
      }
    });
  };

  if (error) return <div>{error}</div>;

  const handleSlugClick = (slug) => {
    window.open(`${process.env.REACT_APP_API}/${slug}`, "_blank");
  };
  const handleCopySlug = (slug) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_API}/${slug}`).then(() => {
      setCopiedSlug(slug);
      setTimeout(() => setCopiedSlug(null), 2000); 
    });
  };

  const columns = [
    { Header: "title", accessor: "title", },
    { Header: "Url", accessor: "slug" },
    { Header: "status", accessor: "status" },
    { Header: "categories", accessor: "categories" },
    { Header: "actions", accessor: "actions" },
  ];
  const rows = pages.map((page, i) => ({
    title: (
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "15px" }}
      >
                   {stripHtml(page.title)}
      </MDTypography>
    ),
    slug: (
      <Tooltip
        title={copiedSlug === page.slug ? "Copied!" : "Click to copy"}
        arrow
        placement="top"
      >
      <MDTypography
        variant="caption"
        component="a"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "15px", cursor:"pointer", color:"#1E90FF" }}
        // onClick={() => handleSlugClick(page.slug)}
        onClick={() => handleCopySlug(page.slug)} 
      >
       { `${process.env.REACT_APP_API}/${page.slug}`}
      </MDTypography>
      </Tooltip>
    ),
    status:(
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "15px" }}
      >
        {page.status}
      </MDTypography>
    ),
    categories:(
      <MDTypography
      variant="caption"
      color="text"
      fontWeight="medium"
      style={{ fontSize: "15px" }}
    >
      {page.categories}
    </MDTypography>
    ),
    actions: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "25px" }}>
        
         <IconButton>
          <NavLink to={`/pages/edit/${page._id}`}>
                <EditIcon />
                </NavLink>
              </IconButton>
              <IconButton  onClick={() => deletePage(page._id)}>
              <DeleteIcon
         
          className="deleteicon"
          style={{ cursor: "pointer" }}
        />
        </IconButton>
      </MDTypography>
    ),
  }));
  return (
    <>
        <DashboardLayout>
          <Sidenav brandName="Connectar" routes={routes} />
          <MDBox  pb={1}>
  <Grid container spacing={2}>
    <Grid item xs={12} lg={12}>
    <Card>
      <MDBox
        mx={2}
        mt={{ xs: -3, sm: -5, md: -10, lg: -10 }}
        py={3}
        px={2}
        variant="gradient"
        sx={{
          backgroundColor: "#1e90ff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          All Pages
        </MDTypography>
        <Link
                          to={`/cms/create/page`} 
                          style={{
                            textDecoration: "none",
                            display: "inline-block",
                          }} 
                        > 
        <Button  style={{
                            color: "black",
                            border: "1px solid white",
                            background: "white",
                            marginRight: "10px",
                          }} 
                          // onClick={() => setIsCreateBlog(true) }
                          >
                       
                        Create A Page
                        </Button>
                        </Link>
      </MDBox>
      {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" py={5}>
                  <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                </Box>
              ) : pages.length > 0 ? (
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" py={5}>
                  <MDTypography variant="h6" color="text">
                    No Pages Found
                  </MDTypography>
                </Box>
              )}
 </Card>
    </Grid>
  </Grid>
</MDBox>

        </DashboardLayout>
         <ToastContainer limit={1} className="custom-toast"/>
      {/* <CreatePage isVisible={IsCreateBlog} Close={() => setIsCreateBlog(false)}  fetchPages={fetchPages} pageId={pageId}/> */}
      {/* <EditPage isVisible={isEditPage} Close={() => {setIsEditPage(false),setPageId(null)}}  fetchPages={fetchPages} pageId={pageId}/> */}
    </>
  );
};

export default PageList;
