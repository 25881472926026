import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import axios from "axios";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { useState, useEffect } from "react";

import Sidenav from "examples/Sidenav";
import routes from "routes";
import DataTable from "examples/Tables/DataTable";

import { ThreeDots } from "react-loader-spinner";

function FeedbackAlluser() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.post(`${process.env.REACT_APP_API_PATH}/feedbackallusers`, {
          token,
        });
        setData(response.data.data);
                setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { Header: "User Name", accessor: "UserName" },
    { Header: "Compatible User Name", accessor: "ReceiverName" },
    { Header: "Category Score", accessor: "score" },
    { Header: "Compatibility Score", accessor: "compatibleAvg" },
    { Header: "Feedback1", accessor: "feedback" },
    { Header: "Feedback2", accessor: "feedback2" },
  ];

  const rows = data?.map((user) => ({
    UserName: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "14px" }}>
        {`${user?.userId.firstname} ${user?.userId.lastname}`}
      </MDTypography>
    ),
    ReceiverName: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "15px" }}>
        {`${user?.compatibleUserId.firstname} ${user?.compatibleUserId.lastname}`}
      </MDTypography>
    ),

    score: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "15px" }}>
        {
          <>
            {`Hobbies: ${user?.score.hobbiesScore}`} <br />
            {`Intelligence: ${user?.score.intelligenceScore}`} <br />
            {`Personality: ${user?.score.personalityScore}`} <br />
            {`Values: ${user?.score.valuesScore}`}
          </>
        }
      </MDTypography>
    ),
    compatibleAvg: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "15px" }}>
        {user?.compatibleAvg}
      </MDTypography>
    ),
    feedback: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "15px" }}>
        {user?.feedback && user?.feedback.length > 0 ? (
          <>
            {user?.feedback.slice(0, 1).map((feedbackItem, index) => (
              <div key={index}>
                {`Given By: ${ user?.userId._id === feedbackItem.userId ? user?.userId.firstname : user?.compatibleUserId.firstname }`}{" "}
                <br />
                {`Comment: ${feedbackItem?.comments}`} <br />
                {`Note: ${feedbackItem?.notes}`} <br />
                {`Emoji: ${feedbackItem?.emoji}`} <br />
                <br />
              </div>
            ))}
          </>
        ) : (
          "-"
        )}
      </MDTypography>
    ),
    feedback2: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "15px" }}>
        {user?.feedback && user?.feedback.length > 0 ? (
          <>
            {user.feedback.slice(1, 2).map((feedbackItem, index) => (
              <div key={index}>
                {`Given By: ${ user?.userId._id === feedbackItem.userId ? user?.userId.firstname : user?.compatibleUserId.firstname }`}{" "}
                <br />
                {`Comment: ${feedbackItem?.comments}`} <br />
                {`Note: ${feedbackItem?.notes}`} <br />
                {`Emoji: ${feedbackItem?.emoji}`} <br />
                <br />
              </div>
            ))}
          </>
        ) : (
          "-"
        )}
      </MDTypography>
    ),
  }));

  return (
    <DashboardLayout>
      <Sidenav color="info" brandName="Connectar" routes={routes} />
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={12}>
           
              <Card>
                <MDBox
                 mx={2}
                 mt={{ xs: -3, sm: -5, md: -10, lg: -10 }}
                 py={4}
                 px={2}
                 variant="gradient"
                 sx={{backgroundColor:"#1e90ff"}}
                 borderRadius="lg"
                 coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" style={{ float: "left" }}>
                    Feedback
                  </MDTypography>
                </MDBox>

                <MDBox pt={3}>
                {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <ThreeDots
                  ariaLabel="loading-indicator"
                  height={100}
                  width={100}
                  strokeWidth={5}
                  strokeWidthSecondary={1}
                  color="#0d6efd"
                  secondaryColor="white"
                />
              </div>
            ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    style={{ fontSize: "30px" }}
                  />
                  )}
                </MDBox>
              </Card>
         
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default FeedbackAlluser;
