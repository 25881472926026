import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { withStyles } from "@mui/styles";
import axios from "axios";
import { Box, useTheme } from "@mui/material";
import questionsData from "../../components/Questionare/All.json";
import CategoryQue from "components/CategoryQue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import StepConnector from "@mui/material/StepConnector";
import useMediaQuery from "@mui/material/useMediaQuery";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

import "../style.css";
import "../Hover.css";
import QusHeader from "./QusHeader";
import MDBox from "components/MDBox";

const Questinere = ({ setIsTestCompleted }) => {
  const steps = ["Personality", "Values", "Interest & Hobbies", "Intelligence"];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [user, setUser] = useState(false);
  const [isBack, setIsBack] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [hideNext, setHideNext] = useState(false);
  const [changeCategory, setChangeCategory] = useState(false);
  const [showFinalSubmit, setShowFinalSubmit] = useState(false);
  const [personalityscore, setPersonalityscore] = useState([]);
  const [hidesteper,sethidesteper] = useState()

  const navigate = useNavigate();
  const CustomStep = withStyles({
    root: {
      "&$active": {
        color: "black !important", // Set the active color to black
        background: "red !important",
      },
    },
    active: {},
    completed: {
      color: "black !important",
    },
  })(Step);

  const CustomStepLabel = withStyles({
    active: {
      color: "blue",
      fontWeight: "bold !important",
    },
    completed: {
      color: "blue",
      fontWeight: "bold !important",
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
    },
  })(StepLabel);

  const CustomStepConnector = withStyles({
    alternativeLabel: {
      top: 7,
      marginLeft: "10px",
    },
    active: {
      "& $line": {
        background: "#1A73e8 !important ",
      },
    },
    completed: {
      "& $line": {
        background: "black !important ",
      },
    },
    line: {
      height: 3,
      marginLeft: "1px",
      marginRight: "10px",
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
      color: "black !important ",
    },
  })(StepConnector);
  
  const updatehidesteper = (dataFromChild) => {
  
    sethidesteper(dataFromChild);
  };

  const handleBackQuestion = (que) => {
    if (!isBack) {
      localStorage.setItem("lastquestion", que + 1);
      localStorage.setItem("lastcategory", currentCategory);
      setIsBack(true);
    }

    if (que + 1 === 0 || currentCategory == 2) {
      setCurrentCategory(currentCategory - 1);
      setCurrentQuestion(questionsData[currentCategory - 1].questions.length - 1);
      setChangeCategory(true);
    } else {
      setCurrentQuestion(que);
      setIsBack(true);
    }

    getAnswers();
  };

  const handleNextQuestion = (que) => {
    if (questionsData[currentCategory].questions.length == que || currentCategory == 2) {
      setCurrentQuestion(0);
      setCurrentCategory(currentCategory + 1);
    } else setCurrentQuestion(que);
  };

  const getAnswers = () => {
    const token = localStorage.getItem("token");
    const isTestStart = true;
    axios
      .post(`${process.env.REACT_APP_API_PATH}/getAnswer`, { token, isTestStart })
      .then((res) => {
        if (res.data.status == 200) {
          setAnswers(res.data.answerss);
        }
      })
      .catch((err) => console.log(err));
  };
  
  const handleFinalSubmit = () => {
    const token = localStorage.getItem("token");
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit your questionnaire?",
      confirmButtonColor: "#0d6efd",
      confirmButtonText: "Submit",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const timezone = dayjs.tz.guess();
        const timezoneOffset = dayjs().tz(timezone).utcOffset();

        axios
          .post(`${process.env.REACT_APP_API_PATH}/testcomplete`, {
            token,
            timezoneOffset,
          })
          .then((res) => {
            navigate(`/userprofile`);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const GetCurrentQuestion = (isSentQuestion = false) => {
    const isTestStart = true;
    const token = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_PATH}/getAnswer`, { token, isTestStart })
      .then((res) => {
        setAnswers(res.data.answerss);
        if (!user) {
          setUser(res.data.data);
        }

        if (res.data.data.iscomplete) {
          window.location.href = "/userprofile";
          return;
        }

        if (isBack) {
          if (currentCategory != 2) {
            let index = 0;
            if (isSentQuestion) {
              index = questionsData[currentCategory].questions.findIndex(
                (obj) => obj.id == isSentQuestion
              );
            } else {
              index = questionsData[currentCategory].questions.findIndex(
                (obj) => obj.id == res.data.data.currentQuestion
              );
            }
            if (index + 1 == questionsData[currentCategory].questions.length) {
              setCurrentQuestion(0);
              setCurrentCategory(currentCategory + 1);
            } else if (index < 0 || index > questionsData[currentCategory].questions.length) {
              setCurrentQuestion(0);
              setCurrentCategory(currentCategory + 1);
            } else {
              setIsNext(true);
              handleBackQuestion(currentQuestion + 1);
            }
          } else {
            setCurrentQuestion(0);
            setCurrentCategory(currentCategory + 1);
          }
        } else {
          if (questionsData[res.data.data.currentCategory].questions) {
            let index = questionsData[res.data.data.currentCategory].questions.findIndex(
              (obj) => obj.id == res.data.data.currentQuestion
            );
            index += 1;
            if (index === questionsData[res.data.data.currentCategory].questions.length) {
              if (questionsData.length - 1 === res.data.data.currentCategory) {
                setShowFinalSubmit(true);
                setCurrentQuestion(
                  questionsData[res.data.data.currentCategory].questions.length - 1
                );
                setCurrentCategory(res.data.data.currentCategory);
              } else {
                setCurrentQuestion(0);
                setCurrentCategory(res.data.data.currentCategory + 1);
              }
            } else {
              setCurrentCategory(res.data.data.currentCategory);
              setCurrentQuestion(index);
            }
          }
        }
      });
  };

  

  const handleAnswer = (question, score, category, subCategory) => {
    const updatedPersonalityscore = [...personalityscore];
    const previousScoreIndex = updatedPersonalityscore.findIndex(
      (answer) => answer.question === question
    );

    if (previousScoreIndex > -1) {
      updatedPersonalityscore[previousScoreIndex] = { question, score, category };
      proceedToNextQuestion();
    } else {
      const lastSixAnswers = [...personalityscore, { score, category }].slice(-6);

      const isSameAnswerSelected = lastSixAnswers.every(
        (answer) => answer.category === category && answer.score === score
      );

      if (isSameAnswerSelected && lastSixAnswers.length === 6) {
        Swal.fire({
          title: "Same Answer Selected",
          text: "You have selected the same answer multiple times in a row. Are you sure the answer is correct?",
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            updatedPersonalityscore.splice(-6, 6).push({ question, score, category });
            proceedToNextQuestion();
          }
        });
      } else {
        updatedPersonalityscore.push({ question, score, category });
        proceedToNextQuestion();
      }
    }

    function proceedToNextQuestion() {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/saveAnswer`, {
          value: score,
          question,
          category,
          subCategory,
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          setPersonalityscore(updatedPersonalityscore);
          GetCurrentQuestion(question);
        })
        .catch((err) => console.log(err));
    }
  };
  const handleHobbies = (quesion, score, category) => {
    const temp = quesion.filter((item) => item !== null && item !== "");
    axios
      .post(`${process.env.REACT_APP_API_PATH}/saveHobbies`, {
        value: temp,
        question: 1501,
        category: 3,
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        GetCurrentQuestion(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isBack && !isNext && !changeCategory) {
      GetCurrentQuestion();
    }

    const lastFilledQue = localStorage.getItem("lastquestion")
      ? localStorage.getItem("lastquestion")
      : 0;

    if (currentCategory < localStorage.getItem("lastcategory")) {
      setHideNext(true);
    } else if (currentQuestion < lastFilledQue) {
      setHideNext(true);
    } else {
      setHideNext(false);
      setIsBack(false);
      localStorage.setItem("lastquestion", 0);
      localStorage.setItem("lastcategory", 0);
    }
    getAnswers();
  }, [currentQuestion, hideNext, currentCategory, showFinalSubmit]);

  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));

  const responsiveStyles = {
    xs: {
      width: isXs ? "100%" : "10%",
      marginLeft: isXs ? "10px" : "unset",
      fontSize: isXs ? "8px" : "inherit",
    },
    sm: {
      width: isSm ? "80%" : "10%",
      marginLeft: isSm ? "50px" : "unset",
    },
    md: {
      width: isMd ? "60%" : "10%",
      marginLeft: isMd ? "100px" : "unset",
    },
    lg: {
      width: "40%",
      marginLeft: "150px",
    },
  };

  return (
    <Box sx={{ width: "100%" }}>
      <QusHeader />
      {user && (
        <>
             {/* {!hidesteper?.personality && !hidesteper?.values && !hidesteper?.intelligence && !hidesteper?.hobbies ? ( */}
        {hidesteper?.personality && hidesteper?.values && hidesteper?.intelligence && hidesteper?.hobbies ? (
          <MDBox mt={5} sx={{ ...responsiveStyles[theme.breakpoints.down("lg")] }}>
            <Stepper
              activeStep={currentCategory}
              alternativeLabel
              connector={<CustomStepConnector />}
            >
              {steps.map((label, index) => (
                <CustomStep key={label}>
                  <CustomStepLabel>{label}</CustomStepLabel>
                </CustomStep>
              ))}
            </Stepper>
          </MDBox>
            ) : null}
          <CategoryQue
            currentQuestion={currentQuestion}
            currentCategory={currentCategory}
            handleAnswer={handleAnswer}
            handleHobbies={handleHobbies}
            handleBackQuestion={handleBackQuestion}
            handleNextQuestion={handleNextQuestion}
            hideNext={hideNext}
            handleAnswers={answers}
            user={user}
            showFinalSubmit={showFinalSubmit}
            handleFinalSubmit={handleFinalSubmit}
            sendDataToParent = {updatehidesteper}
          />
        </>
      )}
    </Box>
  );
};
export default Questinere;