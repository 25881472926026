import Icon from "@mui/material/Icon";
import Category from "layouts/Category/Categories";
import { Typography } from "@mui/material";
import AdminProfile from "layouts/Adminprofile/Profile";
import FeedbackAlluser from "layouts/Adminprofile/FeedbackAlluser";
import RateReviewIcon from '@mui/icons-material/RateReview';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CollectionsIcon from '@mui/icons-material/Collections';
const routes = [
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Companies</Typography>,
    key: "companies",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/companies",
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Categories</Typography>,
    key: "weighting",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/weighting",
    component: <Category />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Score </Typography>,
    key: "snapshot",
    icon: <Icon fontSize="small" sx={{marginTop:"-8px"}}>Count</Icon>,
    route: "/snapshot",
    // component: <Snapshot />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Feedback </Typography>,
    key: "feedbacks",
    icon: <Icon fontSize="small"><RateReviewIcon/></Icon>,
    route: "/feedbacks",
    component: <FeedbackAlluser />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>CMS</Typography>,
    key: "cms/pages",
    icon: <Icon fontSize="small"><CollectionsIcon/></Icon>,
    route: "/cms/pages", 
    component: <AdminProfile />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Profile</Typography>,
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <AdminProfile />,
  },
];

export default routes;
