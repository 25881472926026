// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import { Button } from "@mui/material";
import "./styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddCompanyModal from "./AddCompany";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";
import dayjs from "dayjs";

function Tables() {
  const [com, setCom] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    { Header: "Company Name", accessor: "firstname", width: "45%" },
    { Header: "Company email", accessor: "email" },
    { Header: "actions", accessor: "actions" },
    { Header: "Cron", accessor: "Cron" },
  ];

  const runCompatibilityForCompany = async (company) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_PATH}/run-compatibility `, {
        token,
        companyId: company._id,
      });

      if (response.data.status === 200) {
        Swal.fire({
          title: "Checking compatibility",
          text: "This can take some time, please wait...",
          icon: "success",
          allowOutsideClick: true,
        });
      } else {
        console.log("Compatibility calculation failed!");
      }
    } catch (error) {
      console.error("Error while running compatibility:", error);
    }
  };

  const handleButtonClick = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_API_PATH}/deleteCompany`, { record })
          .then((res) => {
            if (res.data.status == 200) {
              company();
              Swal.fire("Done!", "Your action was successful", "success");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const company = () => {
    axios
      .post(`${process.env.REACT_APP_API_PATH}/getComapnies`)
      .then((res) => {
        if (res.data.status == 200) {
          setCom(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    company();
  }, []);

  const rows = com.map((c, i) => ({
    firstname: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "15px" }}
      >
        {c.firstname}
      </MDTypography>
    ),
    email: (
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "15px" }}
      >
        {c.email}
      </MDTypography>
    ),
    actions: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "25px" }}>
        <DeleteIcon
          onClick={() => handleButtonClick(c)}
          className="deleteicon"
          style={{ cursor: "pointer" }}
        />
      </MDTypography>
    ),
    Cron: (
      <MDButton
        variant="contained"
        style={{
          color: "white",
          marginLeft: "10px",
          background: "#0d6efd",
        }}
        onClick={() => runCompatibilityForCompany(c)}
      >
        Run Cron Job
      </MDButton>
    ),
  }));

  const addCompany = (values) => {
    axios
      .post(`${process.env.REACT_APP_API_PATH}/setComapnies`, values)
      .then((res) => {
        if (res.data.status == 200) {
          Swal.fire("Done!", "Company  addded successfully", "success");
          company();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));

    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <DashboardLayout>
      <Sidenav  brandName="Connectar" routes={routes} />
      <MDBox  pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={{ xs: -3, sm: -5, md: -10, lg: -10 }}
                py={3}
                px={2}
                variant="gradient"
                borderRadius="lg"
                coloredShadow="info"
                sx={{backgroundColor:"#1e90ff"}}
              >
                <Grid>
                  <MDTypography variant="h6" color="white" style={{ float: "left" }}>
                    Companies
                  </MDTypography>
                  <Button
                    style={{
                      float: "right",
                      color: "black",
                      border: "1px solid white",
                      margin: "none",
                      background: "white",
                    }}
                    onClick={() => setShowModal(true)}
                  >
                    Invite Company
                  </Button>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <AddCompanyModal isVisible={showModal} addCompany={addCompany} Close={closeModal} />
      <ToastContainer />
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
