import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "MainComponet/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "MainComponet/Footer";
import { Box, CircularProgress, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

const PageTemplate = () => {
  const apiBaseUrl = process.env.REACT_APP_API_PATH;
  const { slug } = useParams();
  const [pages, setPages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/pages/${slug}`);
        setPages(response.data);
        setLoading(false);
      } catch (err) {
        setError("Page not found");
        setLoading(false);
      }
    };
    fetchPage();
  }, [slug, apiBaseUrl]);

  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" py={5}>
        <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
      </Box>
    );

  if (error || pages.status === "draft") {
    return (
      <>
        <Header />
        <figure className="mt-5">
          <section className="section-widget">
            <div className="container">
              <div className="getInTouch text-center p-5" data-aos="fade-right">
                <MDTypography variant="h5" p={2}>
                  404 - Page Not Found
                </MDTypography>
                <p>The page you're looking for does not exist or is unavailable.</p>
              </div>
            </div>
          </section>
        </figure>

        <div data-aos="fade-right" id="footer" style={{ marginTop: "10rem" }}>
          <Footer />
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <figure className="mt-5">
        <section className="container mt-3">
          <section className="row align-items-center" data-aos="fade-right">
            <Grid container spacing={2}>
              {pages?.featuredImages?.length === 0 ? (
                <Grid item xs={12}>
                  <section>
                    <h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: pages.title.replace(
                            pages.hightitle,
                            `<span style="color:#1e90ff">${pages.hightitle}</span>`
                          ),
                        }}
                      />
                    </h2>
                    <p>{pages.summary}</p>
                  </section>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} md={7}>
                    <section>
                      <h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: pages.title.replace(
                              pages.hightitle,
                              `<span style="color:#1e90ff">${pages.hightitle}</span>`
                            ),
                          }}
                        />
                      </h2>
                      <p>{pages.summary}</p>
                    </section>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    {pages.featuredImages[0].endsWith(".mp4") ? (
                      <video
                        src={pages.featuredImages[0]}
                        alt="Video"
                        style={{
                          height: 250,
                          width: "100%",
                          objectFit: "cover",
                        }}
                        controls
                      />
                    ) : (
                      <img
                        src={pages.featuredImages[0]}
                        alt="Image"
                        style={{
                          height: 250,
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </Grid>
                </>
              )}
            </Grid>

            <section>
              <div
                dangerouslySetInnerHTML={{ __html: pages.content }}
                className="mt-5"
              />
            </section>
          </section>
        </section>
      </figure>

      <div
        data-aos="fade-right"
        id="footer"
        style={{
          marginTop: pages.length === 0 ? "9.2rem" : "13.7rem",
        }}
      >
        <Footer />
      </div>
    </>
  );
};

export default PageTemplate;
