import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  Stack,
} from "@mui/material";
import questionsData from "../components/Questionare/All.json";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MDInput from "./MDInput";
import Aos from "aos";
import "aos/dist/aos.css";
import "./style.css";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Hover.css";
import MDButton from "./MDButton";
import Personality from "../assets/images/shutterstock_2223478821.jpg";
import values from "../assets/images/shutterstock_536595958.jpg";
import Hobbies from "../assets/images/shutterstock_461724928.jpg";
import Intelligence from "../assets/images/shutterstock_2256234141.jpg";

const CustomAutocomplete = styled(Autocomplete)({
  "&.MuiAutocomplete-inputRoot": { cursor: "default", userSelect: "none" },
});

const CategoryQue = (props) => {
  const [currentCategory, setCurrentCategory] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [questions, setQuestions] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [answers, setAnswers] = useState(questionsData[props.currentCategory]?.answers);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [filterImages, setFilterImages] = useState([]);
  const [show, setIsShow] = useState({
    personality: false,
    values: false,
    hobbies: false,
    intelligence: false,
  });

  const steperhide = () => {
    props.sendDataToParent(show);
  };
  useEffect(() => {
    steperhide();
  }, [show]);
  const isSubmitDisabled = selectedOptions.length !== 10;
  const disable = false;
  const hideback = !!(currentCategory === 0 && currentQuestion === 0);

  const setAnswer = (question, score, category, subCategory) => {
    const isReversedRange = questions.id >= 1193 && questions.id <= 1217;

    const scored = isReversedRange ? 6 - parseInt(score) : parseInt(score);

    if (questions.id >= 1193 && questions.id <= 1217) {
      props.handleAnswer(question, scored, category, subCategory);
    } else {
      props.handleAnswer(question, score, category, subCategory);
    }

    if (score === "yes" || score === "no") {
      setSelectedAnswer(score);
    } else {
      setSelectedAnswer(parseInt(score));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentCategory === 0 && currentQuestion === 0) {
        setIsShow({ personality: true, values: false, hobbies: false, intelligence: false });
      } else if (currentCategory === 1) {
        if (currentQuestion <= 49 && currentQuestion !== 15) {
          setIsShow({ personality: false, values: true, hobbies: false, intelligence: false });
        } else {
          setIsShow({ personality: false, values: false, hobbies: false, intelligence: false });
        }
      } else if (currentCategory === 2 && currentQuestion === 0) {
        setIsShow({ personality: false, values: false, hobbies: true, intelligence: false });
      } else if (currentCategory === 3 && currentQuestion === 0) {
        setIsShow({ personality: false, values: false, hobbies: false, intelligence: true });
      }
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoaded(true);
    };

    fetchData();
  }, [currentCategory]);

  useEffect(() => {
    const loadImages = async () => {
      const imageSources = [
        { category: "Personality", src: Personality },
        { category: "Values", src: values },
        { category: "Hobbies", src: Hobbies },
        { category: "Intelligence", src: Intelligence },
      ];

      const promises = imageSources.map(({ category, src }) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = src;

          image.onload = () => resolve({ category, src: image.src });
          image.onerror = () => reject(new Error(`Failed to load image: ${src}`));
        });
      });

      try {
        const loadedImageSrcs = await Promise.all(promises);
        setFilterImages(loadedImageSrcs);
      } catch (error) {
        console.error(error.message);
      }

      setIsLoaded(true);
    };
    
      loadImages();
   
  }, []);
  useEffect(() => {
    setCurrentCategory(props.currentCategory);
    setCurrentQuestion(props.currentQuestion);
    setQuestions(questionsData[currentCategory]?.questions[currentQuestion]);
    setAnswers(questionsData[currentCategory]?.answers);
    setCurrentQuestion(props.currentQuestion);
  }, [currentQuestion, currentCategory, currentQuestion, props, answers, props.showFinalSubmit]);

  const showAnswers = (handleAnswers) => {
    const filteredArray =
      handleAnswers &&
      handleAnswers.filter(
        (item) =>
          item.questionId ==
            questionsData[props.currentCategory]?.questions[props.currentQuestion].id &&
          item.userId == props.user._id
      );

    setansss(filteredArray);
  };

  useEffect(() => {
    showAnswers(props.handleAnswers);
  }, [props.handleAnswers]);

  useEffect(() => {
    Aos.init({
      duration: 300,
      once: true,
      mirror: false,
      easing: "ease-in-out",
    });
    Aos.refresh();
  }, []);

  const handleOptionSelect = (event, value) => {
    if (event.type === "keydown" && event.key === "Backspace") {
      if (value.length === selectedOptions.length) {
        setSelectedOptions([]);
      }
    } else {
      const remainingOptions = value.slice(0, 10);
      if (remainingOptions.length === 10) {
        props.handleHobbies(remainingOptions, "", currentCategory);
      }
      setSelectedOptions(remainingOptions);
    }
  };

  const handleRemoveOption = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedSelectedOptions = prevSelectedOptions.filter(
        (selectedOption) => selectedOption !== option
      );
      return updatedSelectedOptions;
    });
  };
  const setansss = (anss) => {
    const score = anss && anss[0]?.answer;
    if (score == "yes" || score == "no") {
      setSelectedAnswer(score);
    } else {
      const isReversedRange = questions.id >= 1193 && questions.id <= 1217;

      const scoreToShow = isReversedRange ? 6 - parseInt(score) : parseInt(score);
      setSelectedAnswer(scoreToShow);
    }
  };

  useEffect(() => {
    const savedOptions = localStorage.getItem("selectedOptions");
    if (savedOptions && savedOptions != "undefined") {
      setSelectedOptions(JSON.parse(savedOptions));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));
  }, [selectedOptions]);

  return (
    <div>
      <Container sx={{
                        minHeight: '93vh',
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        justifyContent: "center",
                        "@media (width:412px)": {
                          padding:"0px",
                          margin: "0px",
                         },
                        }}>
        <Grid container component="main"  >
          <Grid item xs={12} sm={12} md={12} elevation={12} square>
            <Box
              sx={{
                my: 8,
                display: "flex",
                flexDirection: "column",
                "@media (width:360px)": {
                  padding:"0px",
                  margin: "0px",
                 },
                 "@media (width:640px)": {
                  padding:"0px",
                  margin: "0px",
                 },
                 "@media (width:412px)": {
                  padding:"0px",
                  margin: "0px",
                 },
              }}
            >
              <Grid
                container
                spacing={1}
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  
                }}
                xs={12}
                sm={12}
                md={12}
              >
                <Grid item xs={12} 
                
                        >
                  <CssBaseline />
                  {isLoaded && show.hobbies ? (
                    <>
                      <Container
                        className="min-h-screen bg-gray-100 text-gray-900 flex justify-center"
                        data-aos="fade-up"
                        sx={{ "@media (width:360px)": {
                          padding:"0px",
                          margin: "0px",
                         },
                         "@media (width:640px)": {
                          padding:"0px",
                          margin: "0px",
                         },
                         "@media (width:412px)": {
                          padding:"0px",
                          margin: "0px",
                         },}}
                      >
                       
                        <Card className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
                          <Grid container spacing={0}>
                            <Grid item xs={12} lg={12} xl={12}>
                              <MDBox
                               
                                borderRadius="lg"
                                coloredShadow="info"
                                // mx={2}
                                // mt={-3}
                                p={2}
                                // mb={1}
                                textAlign="center"
                                sx={{  borderBottomLeftRadius: "0",borderBottomRightRadius: "0",backgroundColor:"#1e90ff"}}
                              >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                  Interests/Hobbies
                                </MDTypography>
                              </MDBox>{" "}
                              <Grid container item>
                                <Grid item xs={12} lg={6} xl={6}>
                                  {" "}
                                 
                                  {filterImages
                                    .filter((image) => image.category === "Hobbies")
                                    .map((image) => (
                                      <img
                                        key={image.src}
                                        src={image.src}
                                        alt={image.category}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderBottomLeftRadius: "0.75rem",
                                        }}
                                      />
                                    ))}
                                </Grid>
                                <Grid xs={12} lg={6} xl={6}>
                                  <MDBox pt={4} pb={3} px={3}>
                                    <MDBox
                                      mt={-3}
                                      p={6}
                                      textAlign="center"
                                      sx={{
                                        marginTop: "10px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block",
                                        "@media (min-width:360px)": {
                                          padding:"0px",
                                          marginTop: "5px",
                                         },
                                         "@media (min-width:640px)": {
                                          padding:"0px",
                                          marginTop: "5px",
                                         },
                                      }}
                                    >
                                      Please provide 10 of your favourite Interests/Hobbies
                                      <MDBox mt={4} mb={1} textAlign="center">
                                        <MDButton
                                          onClick={() => {
                                            setIsShow({
                                              Personality: false,
                                              values: false,
                                              hobbies: false,
                                              intelligence: false,
                                            });
                                          }}
                                          sx={{
                                            color: "white !important",
                                            marginTop: "10px",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            display: "block",
                                            backgroundColor:'#0d6efd',
                                            "&:hover": {
                                              backgroundColor: "#1A73E8",
                                            
                                            },
                                          }}
                                        >
                                          Continue
                                        </MDButton>
                                      </MDBox>
                                    </MDBox>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Container>
                    </>
                  ) : (
                    <>
                      {currentCategory != 2 ? (
                        <Grid item xs={12} sm={12} md={12} elevation={12} square >
                          <Box
                            sx={{
                              // mx: 4,
                              display: "flex",
                              flexDirection: "column",
                              margin: "auto",
                              width: "100%",
                           
                            }}
                          >
                            <FormControl>
                              {show.personality || show.values || show.intelligence ? (
                                <>
                                  {!isLoaded && <CircularProgress color="secondary" />}

                                  {isLoaded && show.personality && (
                                    <Container
                                      className="min-h-screen bg-gray-100 text-gray-900 flex justify-center flex-1" 
                                      data-aos="fade-up"
                                      sx={{ "@media (width:360px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },
                                       "@media (width:640px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },
                                       "@media (width:412px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },}}
                                    >
                                      
                                      <Card className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1" sx={{ display: "flex",
                                        justifyContent: "center",
                                        alignItems:"center",
                                        width: "100%",
                                      
                                        flexDirection:"column",
                                        }}>
                                        <Grid container spacing={0}>
                                          <Grid item xs={12} lg={12} xl={12}>
                                            <MDBox
                                              variant="gradient"
                                
                                              borderRadius="lg"
                                              coloredShadow="info"
                                              // mx={2}
                                              // mt={-3}
                                              p={2}
                                              // mb={1}
                                              textAlign="center"
                                              sx={{  borderBottomLeftRadius: "0",borderBottomRightRadius: "0",backgroundColor:"#1e90ff"}}
                                            >
                                              <MDTypography
                                                variant="h4"
                                                fontWeight="medium"
                                                color="white"
                                                mt={1}
                                              >
                                                Personality
                                              </MDTypography>
                                            </MDBox>{" "}
                                            <Grid container item>
                                              <Grid item xs={12} lg={12} xl={6}>
                                                {" "}
                                                
                                                {filterImages
                                                  .filter(
                                                    (image) => image.category === "Personality"
                                                  )
                                                  .map((image) => (
                                                    <img
                                                      key={image.src}
                                                      src={image.src}
                                                      alt={image.category}
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderBottomLeftRadius: "0.75rem",
                                                      }}
                                                    />
                                                  ))}
                                              </Grid>
                                              <Grid xs={12} lg={12} xl={6}>
                                                <MDBox pt={4} pb={3} px={3}>
                                                  <MDBox
                                                    mt={-3}
                                                    p={6}
                                                    textAlign="center"
                                                    sx={{
                                                      marginTop: "10px",
                                                      marginLeft: "auto",
                                                      marginRight: "auto",
                                                      display: "block",
                                                      "@media (min-width:360px)": {
                                                        padding:"0px"
                                                       },
                                                       "@media (min-width:640px)": {
                                                        padding:"0px"
                                                       },
                                                    }}
                                                  >
                                                    Please tell us which of the following best
                                                    describes you or your experiences
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                      <MDButton
                                                        onClick={() => {
                                                          setIsShow({
                                                            Personality: false,
                                                            values: false,
                                                            hobbies: false,
                                                            intelligence: false,
                                                          });
                                                          props.handleNextQuestion(
                                                            currentQuestion + 0
                                                          );
                                                        }}
                                                       
                                                        sx={{
                                                          color: "white !important",
                                                          marginTop: "10px",
                                                          marginLeft: "auto",
                                                          marginRight: "auto",
                                                          display: "block",
                                                          backgroundColor:"#0d6efd",
                                                          "&:hover": {
                                                            backgroundColor: "#1A73E8",
                                                            
                                                          },
                                                        }}
                                                      >
                                                        Continue
                                                      </MDButton>
                                                    </MDBox>
                                                  </MDBox>
                                                </MDBox>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    </Container>
                                  )}
                                  {isLoaded && show.values && (
                                    <Container
                                      className="min-h-screen bg-gray-100 text-gray-900 flex justify-center"
                                      data-aos="fade-up"
                                      sx={{ "@media (width:360px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },
                                       "@media (width:640px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },
                                       "@media (width:412px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },}}
                                    >
                                      
                                      <Card className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
                                        <Grid container spacing={0}>
                                          <Grid item xs={12} lg={12} xl={12}>
                                            <MDBox
                                              variant="gradient"
                                              borderRadius="lg"
                                              coloredShadow="info"
                                
                                              // mx={2}
                                              // mt={-3}
                                              p={2}
                                              // mb={1}
                                              sx={{  borderBottomLeftRadius: "0",borderBottomRightRadius: "0",backgroundColor:"#1e90ff"}}
                                              textAlign="center"
                                            >
                                              <MDTypography
                                                variant="h4"
                                                fontWeight="medium"
                                                color="white"
                                                mt={1}
                                              >
                                                Values
                                              </MDTypography>
                                            </MDBox>{" "}
                                            <Grid container item>
                                              <Grid item xs={12} lg={12} xl={6}>
                                                {" "}
                                             
                                                {filterImages
                                                  .filter((image) => image.category === "Values")
                                                  .map((image) => (
                                                    <img
                                                      key={image.src}
                                                      src={image.src}
                                                      alt={image.category}
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderBottomLeftRadius: "0.75rem",
                                                      }}
                                                    />
                                                  ))}
                                              </Grid>
                                              <Grid xs={12} lg={12} xl={6}>
                                                <MDBox pt={4} pb={3} px={3}>
                                                  <MDBox
                                                    mt={-3}
                                                    p={6}
                                                    textAlign="center"
                                                    sx={{
                                                      marginTop: "10px",
                                                      marginLeft: "auto",
                                                      marginRight: "auto",
                                                      display: "block",
                                                      "@media (min-width:360px)": {
                                                        padding:"0px"
                                                       },
                                                       "@media (min-width:640px)": {
                                                        padding:"0px"
                                                       },
                                                    }}
                                                  >
                                                    Please tell us about how important the following
                                                    values are to you
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                      <MDButton
                                                        onClick={() => {
                                                          setIsShow({
                                                            Personality: false,
                                                            values: false,
                                                            hobbies: false,
                                                            intelligence: false,
                                                          });
                                                          props.handleNextQuestion(
                                                            currentQuestion + 0
                                                          );
                                                        }}
                                                        color="info"
                                                        sx={{
                                                          color: "white !important",
                                                          marginTop: "10px",
                                                          marginLeft: "auto",
                                                          marginRight: "auto",
                                                          display: "block",
                                                          backgroundColor:"#0d6efd",
                                                          "&:hover": {
                                                            backgroundColor: "#1A73E8",
                                                           
                                                          },
                                                        }}
                                                      >
                                                        Continue
                                                      </MDButton>
                                                    </MDBox>
                                                  </MDBox>
                                                </MDBox>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    </Container>
                                  )}

                                  {isLoaded && show.intelligence && (
                                    <Container
                                      className="min-h-screen bg-gray-100 text-gray-900 flex justify-center"
                                      data-aos="fade-up"
                                      sx={{ "@media (width:360px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },
                                       "@media (width:640px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },
                                       "@media (width:412px)": {
                                        padding:"0px",
                                        margin: "0px",
                                       },}}
                                    >
                                      
                                      <Card className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
                                        <Grid container spacing={0}>
                                          <Grid item xs={12} lg={12} xl={12} sm={12} md={12}>
                                            <MDBox
                                              variant="gradient"
                                              borderRadius="lg"
                                              coloredShadow="info"
                                              // mx={2}
                                              // mt={-3}
                                              p={2}
                                              // mb={1}
                                              textAlign="center"
                                              sx={{  borderBottomLeftRadius: "0",borderBottomRightRadius: "0",backgroundColor:"#1e90ff"}}
                                            >
                                              <MDTypography
                                                variant="h4"
                                                fontWeight="medium"
                                                color="white"
                                                mt={1}
                                              >
                                                Intelligence
                                              </MDTypography>
                                            </MDBox>{" "}
                                            <Grid container item>
                                              <Grid item xs={12} lg={12} xl={6} sm={12} md={12}>
                                                {" "}
                                                
                                                {filterImages
                                                  .filter(
                                                    (image) => image.category === "Intelligence"
                                                  )
                                                  .map((image) => (
                                                    <img
                                                      key={image.src}
                                                      src={image.src}
                                                      alt={image.category}
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderBottomLeftRadius: "0.75rem",
                                                      }}
                                                    />
                                                  ))}
                                              </Grid>
                                              <Grid xs={12} lg={12} xl={6} sm={12} md={12}>
                                                <MDBox pt={4} pb={3} px={3}>
                                                  <MDBox
                                                    mt={-3}
                                                    p={6}
                                                    textAlign="center"
                                                    sx={{
                                                      marginTop: "10px",
                                                      marginLeft: "auto",
                                                      marginRight: "auto",
                                                      display: "block",
                                                      "@media (min-width:360px)": {
                                                        padding:"0px"
                                                       },
                                                       "@media (min-width:640px)": {
                                                        padding:"0px"
                                                       },
                                                    }}
                                                  >
                                                    Please tell us which of the following best
                                                    describes you or your experiences
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                      <MDButton
                                                        onClick={() => {
                                                          setIsShow({
                                                            Personality: false,
                                                            values: false,
                                                            hobbies: false,
                                                            intelligence: false,
                                                          });
                                                          props.handleNextQuestion(
                                                            currentQuestion + 0
                                                          );
                                                        }}
                                                       
                                                        sx={{
                                                          color: "white !important",
                                                          marginTop: "10px",
                                                          marginLeft: "auto",
                                                          marginRight: "auto",
                                                          display: "block",
                                                          backgroundColor:"#0d6efd",
                                                          "&:hover": {
                                                            backgroundColor: "#1A73E8",
                                                           
                                                          },
                                                        }}
                                                      >
                                                        Continue
                                                      </MDButton>
                                                    </MDBox>
                                                  </MDBox>
                                                </MDBox>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    </Container>
                                  )}
                                </>
                              ) : (
                                <>
                                 
                                  <Card
                                    sx={{
                                      paddingTop: "35px",
                                      paddingBottom: "48px",
                                      paddingX: 4,
                                      flex: 1,
                                      borderRadius: "12px",
                                      boxShadow:
                                        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                                        display: "flex",
                                        justifyContent: "center",
                                       
                                      
                                        flexDirection:"column",
                                      // border: "2px solid #49A3F1",

                                      // minWidth: "30cm",
                                      marginTop: "20px" ,
                                      "@media (min-width:960px)": {
                                        minWidth: "25.5cm",
                                        
                                      },
                                    }}
                                  >
                                    <div key={questions.id} data-aos="fade-up">
                                      <Typography sx={{ textAlign: "left", paddingBottom: "15px" }}>
                                        {currentQuestion + 1}. {questions?.label}
                                      </Typography>

                                      {show.personality ? (
                                        <></>
                                      ) : (
                                        <>
                                          {" "}
                                          {currentCategory === 0 &&
                                            answers?.map((a) => (
                                              <div
                                                key={a?.id}
                                                style={{
                                                  display: "inline-block",
                                                  marginRight: "20px",
                                                }}
                                              >
                                                <Button
                                                  variant="outlined"
                                                  disabled={disable}
                                                  sx={{
                                                    justifyContent: "flex-start",
                                                    marginTop: "10px",
                                                  }}
                                                  style={
                                                    selectedAnswer == a.score
                                                      ? { background: "#1A73E8" }
                                                      : { background: "white" }
                                                  }
                                                  className="questionHover"
                                                  onClick={() =>
                                                    setAnswer(
                                                      Number(questions.id),
                                                      a?.score,
                                                      currentCategory,
                                                      questions.subcategory
                                                    )
                                                  }
                                                  id={a?.id}
                                                  name="test"
                                                  object={a?.score}
                                                >
                                                  <label
                                                    htmlFor={a?.id}
                                                    sx={{
                                                      fontSize: "15px",
                                                      whiteSpace: "nowrap",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      cursor: "pointer",
                                                    }}
                                                    style={
                                                      selectedAnswer == a.score
                                                        ? { color: "white" }
                                                        : { color: "black" }
                                                    }
                                                  >
                                                    {a?.label}
                                                  </label>
                                                </Button>
                                              </div>
                                            ))}
                                        </>
                                      )}

                                      {show.values ? (
                                        <></>
                                      ) : (
                                        <>
                                          {currentCategory === 1 && (
                                            <Box sx={{ marginTop: "20px" }}>
                                              <Stack
                                                direction={{ xs: "column", sm: "row" }}
                                                spacing={{ xs: 1, sm: 2, md: 4 }}
                                                sx={{
                                                  "@media (max-width: 990px) and (min-width: 500px)": {
                                                    flexDirection: "column", 
                                                    padding:"0px !important"
                                                  },
                                                }}
                                              >
                                                {answers?.map((a) => (
                                                  <div key={a.id}>
                                                    <label htmlFor={a.id} />
                                                    <Button
                                                      sx={{
                                                        color: "black   !important",
                                                        cursor: "pointer",
                                                        marginTop: "10px",
                                                        width: "50px",
                                                      }}
                                                      key={a.id}
                                                      disabled={disable}
                                                      variant="outlined"
                                                      style={
                                                        selectedAnswer == a.score
                                                          ? { background: "#1A73E8" }
                                                          : { background: "white" }
                                                      }
                                                      onClick={() =>
                                                        setAnswer(
                                                          Number(questions.id),
                                                          a?.score,
                                                          currentCategory,
                                                          questions.subcatagory
                                                        )
                                                      }
                                                      className="questionHover"
                                                    >
                                                      <span
                                                        style={
                                                          selectedAnswer == a.score
                                                            ? { color: "white" }
                                                            : { color: "black" }
                                                        }
                                                      >
                                                        {a.score}
                                                      </span>
                                                    </Button>
                                                  </div>
                                                ))}
                                              </Stack>
                                              <Box sx={{ flexGrow: 1 }}>
                                                <Grid container spacing={3} mt={2}>
                                                  <Grid item xs>
                                                    <p>Not at all important (1 to 3)</p>
                                                  </Grid>
                                                  <Grid item xs style={{ marginLeft: "auto" }}>
                                                    <p>Extremely Important (8 to 10)</p>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            </Box>
                                          )}
                                        </>
                                      )}
                                      {show.intelligence ? (
                                        <></>
                                      ) : (
                                        <>
                                          {currentCategory === 3 && (
                                            <div
                                              style={{
                                                display: "flex",
                                                marginRight: "20px",
                                              }}
                                            >
                                              <Button
                                                variant="outlined"
                                                onClick={() =>
                                                  setAnswer(
                                                    Number(questions.id),
                                                    "yes",
                                                    currentCategory,
                                                    questions.subcategory
                                                  )
                                                }
                                                sx={{
                                                  width: "100px",
                                                  height: "35px",
                                                  marginLeft: "30px",
                                                  color: "black !important",
                                                  marginTop: "10px",
                                                  "&:hover": {
                                                    backgroundColor: "#0d6efd",
                                                    color: "white",
                                                  },
                                                }}
                                                className="questionHover"
                                                style={
                                                  selectedAnswer == "yes"
                                                    ? { background: "#0d6efd" }
                                                    : { background: "white" }
                                                }
                                              >
                                                Yes
                                              </Button>
                                              <br />
                                              <Button
                                                variant="outlined"
                                                onClick={() =>
                                                  setAnswer(
                                                    Number(questions.id),
                                                    "no",
                                                    currentCategory,
                                                    questions.subcategory
                                                  )
                                                }
                                                className="questionHover"
                                                sx={{
                                                  width: "100px",
                                                  height: "35px",
                                                  color: "black !important",
                                                  marginTop: "10px",
                                                  marginLeft: "30px",
                                                  "&:hover": {
                                                    backgroundColor: "#0d6efd",
                                                    color: "white",
                                                  },
                                                }}
                                                style={
                                                  selectedAnswer == "no"
                                                    ? { background: "#0d6efd" }
                                                    : { background: "white" }
                                                }
                                              >
                                                No
                                              </Button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </Card>
                                </>
                              )}

                              {!show.personality && !show.values && !show.intelligence ? (
                                <div style={{}}>
                                  <Typography mt={3}>
                                    {!hideback && (
                                      <MDButton
                                        
                                        onClick={() =>
                                          props.handleBackQuestion(currentQuestion - 1)
                                        }
                                        sx={{
                                          color: "black !important",
                                          marginTop: "10px",
                                          float: "left",
                                          marginLeft: "22px",
                                          backgroundColor:"#0d6efd",
                                          "&:hover": {
                                            backgroundColor: "#0d6efd",
                                            color: "white",
                                          },
                                        }}
                                      >
                                        <ChevronLeftIcon style={{ color: "white" }} />
                                        <span style={{ color: "white" }}> back</span>{" "}
                                      </MDButton>
                                    )}
                                    {props.hideNext && (
                                      <MDButton
                                        onClick={() =>
                                          props.handleNextQuestion(currentQuestion + 1)
                                        }
                                        sx={{
                                          color: "black !important",
                                          marginTop: "10px",
                                          // marginLeft: "30px",
                                          float: "right",
                                          marginLeft: "10px",
                                          marginRight: "10px",
                                          backgroundColor:"#0d6efd",
                                          "&:hover": {
                                            backgroundColor: "#0d6efd",
                                            color: "white",
                                          },
                                        }}
                                      >
                                        <span style={{ color: "white" }}> next</span>{" "}
                                        <NavigateNextIcon style={{ color: "white" }} />
                                      </MDButton>
                                    )}

                                    {props.showFinalSubmit &&
                                      questionsData[questionsData.length - 1].questions.length -
                                        1 ==
                                        currentQuestion &&
                                      currentCategory == 3 && (
                                        <MDButton
                                          onClick={() => props.handleFinalSubmit()}
                                          style={{ float: "right", marginTop: "10px", backgroundColor:"#0d6efd",color:"white" }}
                                        >
                                          Submit
                                        </MDButton>
                                      )}
                                  </Typography>
                                </div>
                              ) : (
                                <></>
                              )}
                            </FormControl>
                          </Box>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          spacing={1}
                          item
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: "30px" ,
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          
                        >
                          
                          <Grid item xs={12} md={12} lg={12} style={{ userSelect: "none" }} >
                            <Card variant="outlined" sx={{ boxShadow: 4, maxWidth: 600, mx: "auto"}}>
                              <MDBox
                               variant="gradient"
                               sx={{ backgroundColor:"#1e90ff"}}
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                              >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                  You can select only 10 Interests and Hobbies
                                </MDTypography>
                              </MDBox>
                              <Typography variant="h5" align="center" mt={1} gutterBottom>
                                Interests and Hobbies
                              </Typography>
                              <CardContent>
                                <CustomAutocomplete
                                  multiple
                                  limitTags={10}
                                  options={questionsData[currentCategory].questions}
                                  renderInput={(params) => (
                                    <MDInput
                                      {...params}
                                      label="Select your interests/hobbies"
                                      variant="outlined"
                                      fullWidth
                                      inputProps={{
                                        ...params.inputProps,
                                        style: { cursor: "default", userSelect: "none" },
                                      }}
                                    />
                                  )}
                                  onChange={handleOptionSelect}
                                  value={selectedOptions}
                                  disableClearable
                                  style={{ marginBottom: "10px" }}
                                  renderTags={() => null}
                                  renderOption={(props, option, { selected }) => (
                                    <li
                                      {...props}
                                      style={{
                                        backgroundColor: selected ? "#e0e0e0" : "transparent",
                                      }}
                                    >
                                      {option}
                                    </li>
                                  )}
                                />
                                {selectedOptions.map((option, i) => (
                                  <div key={i} style={{ display: "flex", alignItems: "center" }}>
                                    <li style={{ fontSize: "14px", marginTop: "10px" }}>
                                      {option}
                                    </li>
                                    <IconButton
                                      size="small"
                                      onClick={() => handleRemoveOption(option)}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </div>
                                ))}
                              </CardContent>
                            </Card>
                          </Grid>

                          {!show.hobbies && (
                         <Grid
                         item
                         xs={12}
                         sm={12}
                         md={12}
                         lg={12}
                         elevation={12}
                         square
                         sx={{ width:"100%", mx: "auto" , display: "flex",
                         flexDirection: "column",
                         verticalAlign: "middle",
                         justifyContent: "center",}}
                       >
                              <Typography mt={3}>
                                {!hideback && (
                                      <Grid xs={12} sm={12} md={12} lg={12}>
                                  <MDButton
                                    
                                    onClick={() => {
                                      props.handleBackQuestion(currentQuestion - 1);
                                    }}
                                    sx={{
                                      color: "black !important",
                                      marginTop: "10px",
                                      alignItems: "left",
                                      float: "left",
                                      backgroundColor:"#0d6efd",
                                      "&:hover": {
                                        backgroundColor: "#1A73E8",
                                        color: "white",
                                      },
                                    }}
                                  >
                                    <ChevronLeftIcon style={{ color: "white" }} />
                                    <span style={{ color: "white" }}> back</span>
                                  </MDButton>
                                  </Grid>
                                )}
                                {props.hideNext && (
                                      <Grid xs={12} sm={12} md={12} lg={12}>
                                  <MDButton
                                    
                                    onClick={() => props.handleNextQuestion(currentQuestion + 1)}
                                    disabled={isSubmitDisabled}
                                    sx={{
                                      color: "black !important",
                                      marginTop: "10px",
                                      marginLeft: "30px",
                                      alignItems: "right",
                                      float: "right",
                                      backgroundColor:"#0d6efd",
                                      "&:hover": {
                                        backgroundColor: "#1A73E8",
                                        color: "white",
                                      },
                                    }}
                                  >
                                    <span style={{ color: "white" }}> next</span>
                                    <NavigateNextIcon style={{ color: "white" }} />
                                  </MDButton>
                                  </Grid>
                                )}
                              </Typography>
                           </Grid>
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CategoryQue;