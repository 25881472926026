// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Avatar, Tooltip } from "@mui/material";
import routes from "routes";
import Sidenav from "examples/Sidenav";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetCompanyPasswordModal from "./ChangeCompanyPassword";

function CompanyProfile() {
  const [user, setUser] = useState();
  const [showResetModal, setResetShowModal] = useState(false);
  const [update, setUpdate] = useState(true);

  const navigate = useNavigate();

  const handleChangeImage = async (e) => {
    if (e.target.files.length) {
      const token = localStorage.getItem("token");

      const img = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      };

      const formData = new FormData();
      formData.append("file", img.data);

      axios

        .post(`${process.env.REACT_APP_API_PATH}/image/${token}`, formData)
        .then((res) => {
          validateUser();
        })
        .catch((err) => console.log(err));
    }
    toast.success("Image Updated  !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const ResetPassword = (values) => {
    const token = localStorage.getItem("token");

    axios

      .post(`${process.env.REACT_APP_API_PATH}/resetPassword`, { values, token })
      .then((res) => {
        if (res.data.status == 200) {
          localStorage.clear();
          setResetShowModal(false);

          Swal.fire("Done!", "you can now logged in with new password", "success");
          navigate("/profile");
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const closeModal = () => {
    setResetShowModal(false);
  };

  const modalopen = () => {
    setResetShowModal(true);
  };

  const validateUser = () => {
    const token = localStorage.getItem("token");

    axios

      .post(`${process.env.REACT_APP_API_PATH}/validuser`, { token })
      .then((res) => {
        if (res.data.status == 200) {
          setUser(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    validateUser();
  }, []);

  const handleChange = (e) => {
    setUpdate(false);
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const updateProfile = () => {
    setUpdate(true);
    const token = localStorage.getItem("token");
    toast.success("Successfully Updated!", {
      position: toast.POSITION.TOP_RIGHT,
    });

    axios

      .post(`${process.env.REACT_APP_API_PATH}/updateprofile`, { user, token })
      .then((res) => {
        if (res.data.status == 200) {
          setUser(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <DashboardLayout>
      <Sidenav color="Primary" brandName="Connectar" routes={routes} />
      <MDBox >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                borderRadius="lg"
                coloredShadow="info"
                variant="gradient"
                sx={{backgroundColor:"#1e90ff"}}
              >
                <MDBox
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    // marginTop: "-6%",
                  }}
                >
                  <label htmlFor="upload-button">
                    {user?.image ? (
                      <img
                        src={user.image}
                        alt="dummy"
                        width="150"
                        height="150"
                        style={{ borderRadius: "50%", cursor: "pointer" }}
                        sx={{
                          height: 100,
                          mb: 2,
                          width: 100,
                        }}
                      />
                    ) : (
                      <Tooltip title="Upload Image">
                        <span className="fa-stack fa-2x mt-3 mb-2">
                          <Avatar
                            sx={{
                              height: 100,
                              mb: 2,
                              width: 100,
                              cursor: "pointer",
                            }}
                            style={{ height: 100, width: 100 }}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </label>
                  <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    sx={{
                      height: 100,
                      mb: 2,
                      width: 100,
                    }}
                    onChange={handleChangeImage}
                  />
                  <ToastContainer />
                  <Tooltip title="Click on the image to update your profile image">
                    <MDTypography
                      variant="h6"
                      color="white"
                      alignItems="center"
                      mt={1}
                      onClick={handleChangeImage}
                      style={{ cursor: "pointer" }}
                    >
                      Upload image
                    </MDTypography>
                  </Tooltip>
                  <MDTypography
                    variant="h5"
                    color="white"
                    alignItems="center"
                    mt={2}
                    style={{ cursor: "pointer" }}
                  >
                    Profile
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mx={2} mt={3} py={3} px={2}>
                <MDBox>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "17px" }}>Company Name</label>
                        <input
                        className="form-control"
                          type="text"
                          fullWidth
                          name="firstname"
                          value={user?.firstname}
                          onChange={(e) => handleChange(e)}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "17px" }}>Company Email</label>
                        <input
                        className="form-control"
                          type="email"
                          fullWidth
                          name="email"
                          value={user?.email}
                          onChange={(e) => handleChange(e)}
                        />
                      </MDBox>
                    </Grid>
                    
                  </Grid>
                </MDBox>
            
                <MDBox>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "17px" }}>Contact Number</label>
                        <input
                        className="form-control"
                          type="tel"
                          fullWidth
                          name="contact"
                          value={user?.contact}
                          onChange={(e) => handleChange(e)}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "17px" }}>Company Address</label>
                        <input
                        className="form-control"
                          type="text"
                          fullWidth
                          name="city"
                          value={user?.city}
                          onChange={(e) => handleChange(e)}
                        />
                      </MDBox>
                    </Grid>
                    
                  </Grid>
                </MDBox>
               
                <MDBox >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "17px" }}>Primary Contact Name</label>
                        <input
                        className="form-control"
                          type="text"
                          fullWidth
                          name="primaryContactName"
                          value={user?.primaryContactName}
                          onChange={(e) => handleChange(e)}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "17px" }}>Primary Contact Email</label>
                        <input
                        className="form-control"
                          type="email"
                          fullWidth
                          name="primaryContactEmail"
                          value={user?.primaryContactEmail}
                          onChange={(e) => handleChange(e)}
                        />
                      </MDBox>
                    </Grid>
                    
                  </Grid>
                </MDBox>
                <MDBox>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "17px" }}>Primary Contact Number</label>
                        <input
                        className="form-control"
                          type="tel"
                          fullWidth
                          name="primaryContactNumber"
                          value={user?.primaryContactNumber}
                          onChange={(e) => handleChange(e)}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2}>
                        <label style={{ fontSize: "17px" }}>Primary Contact Address</label>
                        <input
                        className="form-control"
                          type="text"
                          fullWidth
                          name="primaryContactAddress"
                          value={user?.primaryContactAddress}
                          onChange={(e) => handleChange(e)}
                        />
                      </MDBox>
                    </Grid>
                    
                  </Grid>
                </MDBox>
                <MDBox>
                  <Grid container spacing={1}>
                  
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2} mt={2}>
                        <MDButton
                          variant="text"
                          color="info"
                          fullWidth
                          onClick={modalopen}
                          style={{ border: "1px solid hsl(240deg 5% 60%)" }}
                        >
                          Reset Password
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <MDBox mb={2} mt={2}>
                        <MDButton
                          color="info"
                          fullWidth
                          onClick={updateProfile}
                          disabled={update}
                          sx={{backgroundColor:'#0d6efd'}}
                        >
                          save details
                        </MDButton>
                        <ToastContainer />
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ResetCompanyPasswordModal
        isVisible={showResetModal}
        addCompany={ResetPassword}
        Close={closeModal}
      />
      <ToastContainer limit={1} />
      <Footer />
    </DashboardLayout>
  );
}

export default CompanyProfile;