import { Icon, Typography } from "@mui/material";
import CompanyProfile from "components/CompanyDashboard/CompanyProfile";
import Users from "components/CompanyDashboard/UsersTable";
import UploadUsers from "components/CompanyDashboard/uploadUsers";

const companyRoutes = [
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}> Users </Typography>,
    key: "addusers",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/addusers",
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}> Upload Users </Typography>,
    key: "uploadusers",
    icon: <Icon fontSize="small">upload</Icon>,
    route: "/uploadusers",
    component: <UploadUsers />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}> Profile </Typography>,
    key: "companyprofile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/companyprofile",
    component: <CompanyProfile />,
  },
];

export default companyRoutes;
