import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { useState, useEffect } from "react";

import Sidenav from "examples/Sidenav";
import routes from "routes";
import DataTable from "examples/Tables/DataTable";

import { ThreeDots } from "react-loader-spinner";
import MDInput from "components/MDInput";

function Snapshot() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_PATH}/getPersonalityScores`, { token })
      .then((response) => {
        setData(response.data.usersWithScores || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const columns = [
    { Header: " User Name", accessor: "UserName" },

    { Header: "personality Score", accessor: "personalityScore" },
    { Header: "values Score", accessor: "valuesScore" },
    { Header: "Hobbies Score", accessor: "HobbiesScore" },
    { Header: "Intelligence Score", accessor: "IntlligenceScore" },
  ];
  const filteredData = data.filter((user) => {
    const userName = user.firstname ? user.firstname : "";
    const userLastName = user.lastname ? user.lastname : "";
    const userEmail = user.email ? user.email : "";
    
    const fullName = `${userName} ${userLastName}`.toLowerCase();
    return fullName.includes(searchValue.toLowerCase())  || userEmail.includes(searchValue.toLowerCase());
  });
  useEffect(() => {
    if (filteredData.length === 0 && data.length > 0) {
      setError("User not found");
    } else {
      setError("");
    }
  }, [filteredData, data]);
  const rows = filteredData.map((user) => ({
    UserName: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "14px" }}
        onClick={() => history.push(`/user/${user.userId}`)}
      >
        {`${user.firstname} ${user.lastname}`}<br/>
        {`${user.email}`}
      </MDTypography>
    ),

    personalityScore: (
      <table>
        <tbody>
          {Object.keys(user.personalityScores).map((subcategory) => (
            <tr key={subcategory}>
              <td style={{ padding: "1px" }}>{subcategory}:</td>
              <td style={{ marginRight: "10px", padding: "2px" }}>
                {user.personalityScores[subcategory]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ),

    valuesScore: (
      <div key="valuesScore">
        {user.valuesScore &&
          user.valuesScore.map((score, index) => (
            <div key={index}>{`Q${index + 1}. ${score}`}</div>
          ))}
      </div>
    ),
    HobbiesScore: (
      <div key="HobbiesScore">
        {user.hobbiesScore &&
          user.hobbiesScore.map((hobby, index) => (
            <div key={index}>{`${index + 1}. ${hobby}`}</div>
          ))}
      </div>
    ),

    IntlligenceScore: (
      <table>
        <tbody>
          {Object.keys(user.intelligenceScore).map((subcategory) => (
            <tr key={subcategory}>
              <td style={{ padding: "1px" }}>{subcategory}:</td>
              <td style={{ marginRight: "10px" }}>
                Yes:{" "}
                <span style={{ margin: "0 5px" }}>{user.intelligenceScore[subcategory].yes}</span>
              </td>

              <td style={{ marginRight: "10px" }}>
                No:{" "}
                <span style={{ margin: "0 5px" }}>{user.intelligenceScore[subcategory].no}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ),
  }));

  return (
    <DashboardLayout>
      <Sidenav color="info" brandName="Connectar" routes={routes} />
      <MDBox  pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={12}>
            
              <Card>
                <MDBox
                  mx={2}
                  mt={{ xs: -3, sm: -5, md: -10, lg: -10 }}
                  py={3}
                  px={3}
                  variant="gradient"
                sx={{backgroundColor:"#1e90ff"}}
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" style={{ float: "left" }}>
                    Score Table
                  </MDTypography>

                  <MDInput
                  mt={5}
                  label="Search Name"
                  variant="outlined"
                  style={{
                    float: "right",
                    fontSize: "18px",
                    background: "white",
                    borderRadius: "9px",
                    color: "black",
                  }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                </MDBox>
                {error ? (
                <MDBox pt={2} textAlign="center">
                  <MDTypography
                    variant="body1"
                    color="error"
                    sx={{ fontSize: "30px", fontWeight: "bold" }}
                  >
                    {error}
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox pt={3}>
                  {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <ThreeDots
                  ariaLabel="loading-indicator"
                  height={100}
                  width={100}
                  strokeWidth={5}
                  strokeWidthSecondary={1}
                  color="#0d6efd"
                  secondaryColor="white"
                />
              </div>
            ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    style={{ fontSize: "30px" ,backgroundColor:"#0d6efd"}}
                  />
                  )}
                </MDBox>)}
              </Card>
           
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Snapshot;
